import React from 'react'
import './Header.css'

class Header extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            hasScrolled: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll = (event) => {
        const scrollTop = window.pageYOffset

        if (scrollTop > 50) {
            this.setState({ hasScrolled: true })
        } else {
            this.setState({ hasScrolled: false })
        }
    }

    render() {
        return(
            <div className={this.state.hasScrolled ? 'header headerScrolled' : 'header'}>
                <div className="headerGroup">
                    <a href="/" className="brand"><img src={require('../images/logo-designcode.svg')} /></a>
                    <a href="/courses">Courses</a>
                    <a href="/downloads">Downloads</a>
                    <a href="/workshops">Workshops</a>
                    <button>Buy</button>
                </div>
            </div>
        )
    }
}

export default Header