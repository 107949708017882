import React from 'react'
import styled from 'styled-components'
import './App.css'
import Header from './components/Header'
import Card from './components/Card'
import Section from './components/Section'
import staticdata from './staticdata.json'
import Cell from './components/Cell'

const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #94A4BA;
  text-align: center;
`

const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

class App extends React.Component {
  render() {
    return(
      <div>
        <Header />
        <div className="hero">
          <div className="heroGroup">
            <h1>Learn to <br /> design and code modern apps</h1>
            <p>Complete courses about the best tools and design systems. Prototype and build apps with React and Swift.</p>
            <a href="#" className="videoBtn">Watch the video</a>

            <div className="logos">
              <img src={require('./images/logo-sketch.png')} width="50" />
              <img src={require('./images/logo-figma.png')} width="50" />
              <img src={require('./images/logo-studio.png')} width="50" />
              <img src={require('./images/logo-framer.png')} width="50" />
              <img src={require('./images/logo-react.png')} width="50" />
              <img src={require('./images/logo-swift.png')} width="50" />
            </div>

            <svg width="100%" height="172" viewBox="0 0 100% 172" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path  fill="white">
                <animate repeatCount="indefinite" fill="freeze" attributeName="d" dur="10s" values="M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z;
                M0 87.1596C316 87.1597 444 160 884 52.0001C1324 -55.9999 1320.29 34.966 1538 71.251C1814 117.251 2156 189.252 2560 87.1597V233.161L0 233.161V87.1596Z;
                M0 53.6584C158 11.0001 213 0 363 0C513 0 855.555 115.001 1154 115.001C1440 115.001 1626 -38.0004 2560 53.6585V199.66L0 199.66V53.6584Z;
                M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z"/>
              </path>
            </svg>
          </div>
        </div>

        <div className="cards">
          <h2>11 courses, more coming</h2>
          <div className="cardGroup">
            <Card title="Design System" text="10 sections" image={require('./images/wallpaper.jpg')} />
            <Card title="React for Designers" text="12 sections" image={require('./images/wallpaper2.jpg')} />
            <Card title="Sound Design" text="5 sections" image={require('./images/wallpaper3.jpg')} />
            <Card title="ARKit 2" text="10 sections" image={require('./images/wallpaper4.jpg')} />
          </div>
        </div>

        <Section image={require('./images/wallpaper2.jpg')} logo={require('./images/logo-react.png')} title="React for Designers" text="Learn how to build a modern site using React and the most efficient libraries to get your site/product online. Get familiar with components, Grid CSS, animations, interactions, dynamic data with Contentful and deploying your site with Netlify." />

        <SectionCaption>12 sections - 6 hours</SectionCaption>
        <SectionCellGroup>
          {staticdata.cells.map(cell => (
            <Cell title={cell.title} image={cell.image} />
          ))}
        </SectionCellGroup>
      </div>
    )
  }
}

export default App;
